<template>
  <v-container>
    <page-title :title="providerName"></page-title>
    <div>
      <provider-info-readonly @setFormKey="setFormKey" />
    </div>
    <div style="display: none">
      <v-row
        v-if="
          status ==
            this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REQUEST
        "
      >
        <v-col>
          <!--          <back />-->
        </v-col>
      </v-row>

      <v-row
        v-if="
          status ==
            this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_COOPERATION
        "
      >
        <v-col cols="6">
          <!--          <back />-->
        </v-col>
        <v-col cols="6">
          <updateCooperation
            type="cancel"
            :providerId="providerId"
            :storeId="storeId"
            :name="providerName"
            role="store"
            routeName="store-cooperation-cancel"
          />
        </v-col>
      </v-row>

      <!--      <v-row-->
      <!--        v-if="-->
      <!--          status ==-->
      <!--          this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT-->
      <!--        "-->
      <!--      >-->
      <!--        <v-col cols="6">-->
      <!--          <back />-->
      <!--        </v-col>-->
      <!--        <v-col cols="6">-->
      <!--          <updateCooperation-->
      <!--            type="reRequest"-->
      <!--            :providerId="providerId"-->
      <!--            :storeId="storeId"-->
      <!--            :to="{-->
      <!--              name: 'store-register',-->
      <!--              params: { providerId },-->
      <!--              query: { forceUpdate: true },-->
      <!--            }"-->
      <!--          />-->
      <!--        </v-col>-->
      <!--      </v-row>-->
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    formKey: "",
    firstTime: true,
  }),
  components: {
    providerInfoReadonly: () =>
      import("@/modules/provider/views/providerInfoReadonly.vue"),
    // back: () => import("@/modules/base/components/statusBtn/back.vue"),
    updateCooperation: () =>
      import("@/modules/base/components/statusBtn/updateCooperation.vue"),
  },
  computed: {
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`];
    },
    paymentType() {
      if (!this.formData) return null;
      return this.formData.pivot.payment_type;
    },
    status() {
      if (!this.formData) return "";
      if (!this.formData.pivot) return "";
      return this.formData.pivot.status;
    },
    providerId() {
      return this.$route.params.cooperateProviderId;
    },
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
    providerName() {
      if (!this.formData) return "";
      if (!this.formData.pivot) return "";
      return this.formData.pivot.name;
    },
    reason() {
      if (!this.formData) return "";
      if (!this.formData.pivot) return "";
      return this.formData.pivot.reason;
    },
  },
  watch: {
    paymentType() {
      this.changePayment();
    },
  },
  methods: {
    setFormKey(key) {
      this.formKey = key;
    },
    async changePayment() {
      if (this.firstTime) {
        this.firstTime = false;
        return;
      }
      const params = {
        payment_type: this.paymentType,
      };
      this.$store.dispatch("loading/active");
      try {
        const res = await this.$api.collection.providerApi.changePayment(
          this.providerId,
          this.storeId,
          params
        );
        this.$snotify.success("更新付款方式成功");
      } catch (err) {
        console.error(err);
        this.$snotify.error("更新付款方式失敗");
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
  },
};
</script>
